/*

👋 “Hey” from the Praxis team!

'apiConfig' is one pattern that can be used to set environment-based values at build time.
This happens by creating an environment variable REACT_APP_ENV, and then matching corresponding
keys to each environment in the 'envConfigs' object below. To learn more about how to set
REACT_APP_ENV, view the README generated with this app.


For an alternative pattern - using TAP configuration to set environment-based values at cluster initialization - see:
@praxis/component-runtime-env - https://praxis.prod.target.com/components/runtime-env

*/

import merge from 'lodash/merge'

// configurations shared between most or all environments can be stored in commonConfig
const commonConfig = {
  auth: {
    authorizationUrl:
      'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    logoutUrl:
      'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
  },
}

const envConfigs = {
  // dev is the default development environment set by .env.development and the default pipeline in .vela.yml
  dev: {
    auth: {
      clientId: 'crisis_npe_im',
    },
    api: {
      messageUrl: {
        internal:
          'https://stgapi-internal.target.com/crisismessaging/v1/crisisprompt?key=',
        external:
          'https://stage-api.target.com/crisismessaging/v1/crisisprompt?key=',
      },
      key: 'hDQIhgB2fZU0vTyUeu6',
      gatewayKey: '1ff4dc4aae6755e2c39d577caa9e368551b987bf',
      jobTitleCode: ['40037', '40038', '60189', '101210', '54542'],
    },
  },
  /*
  // additional environments can be added:
  stg: {

  },
  */
  prod: {
    auth: {
      // keys in envConfigs will overwrite keys from commonConfig
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      logoutUrl:
        'https://logonservices.iam.target.com/login/responses/logoff.html',
      clientId: 'crisis_prod_im',
    },
    api: {
      messageUrl: {
        internal:
          'https://api-internal.target.com/crisismessaging/v1/crisisprompt?key=',
        external: 'https://api.target.com/crisismessaging/v1/crisisprompt?key=',
      },
      key: 'hDQIhgB2fZU0vTyUeu6',
      gatewayKey: '1a6752d0a3fe90f3cd8bb9b95e0654da3b97f909',
      jobTitleCode: [
        '40037',
        '54542',
        '60189',
        '9027',
        '4061',
        '100395',
        '100265',
        '9011',
        '0884',
        '100259',
        '25249',
        '101069',
        '25394',
        '9840',
        '9843',
        '100260',
        '9841',
        '25213',
        '9830',
        '30135',
        '100268',
        '9111',
        '9165',
        '7940',
        '42918',
        '40038',
        '100261',
        '9102',
        '7524',
        '3007',
        '101849',
        '10085',
        '7034',
        '1785',
        '7524',
        '01210',
        '62335',
        '100037',
        '40207',
        '54968',
        '54993',
        '100037',
        '100280',
        '40207',
        '100242',
        '40226',
        '101064',
        '3060',
        '3067',
        '62278',
        '40222',
        '62001',
        '40253',
        '4129',
        '41723',
        '101210',
      ],
    },
  },
}

const appEnv = process.env.REACT_APP_ENV
const config = envConfigs[appEnv]

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
const apiConfig = merge(commonConfig, config)

export default apiConfig

// The following code block is purely for demonstration purposes and can be removed.
if (process.env.NODE_ENV === 'development') {
  console.log(`${appEnv} ENV apiConfig:`, apiConfig)
}
