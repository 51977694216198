import { Heading, Button, Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { Spinner } from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import apiConfig from '../apiConfig'

let urlEnv = apiConfig.api.messageUrl.external

if (
  window.location.href.includes('prod') ||
  window.location.href.includes('dev') ||
  window.location.href.includes('localhost')
) {
  urlEnv = apiConfig.api.messageUrl.internal
}

const Message = () => {
  const { session, logout } = useAuth()

  const [success, setSuccess] = useState(false)

  const [error, setError] = useState(false)

  const jobcode = apiConfig.api.jobTitleCode

  const [isLoading, setLoading] = useState(false)

  const token = session.accessToken

  console.log('session:', session)

  const postPrompt = async (prompt, location) => {
    setLoading(true)
    const jsonObject = {
      promptText: prompt,
      locationNumber: location,
      authKey: apiConfig.api.key,
    }

    console.log('jsonObject:', jsonObject)
    try {
      const response = await fetch(urlEnv + apiConfig.api.gatewayKey, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(jsonObject),
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response
      setLoading(false)
      setSuccess(true)
      console.log('Success:', data)
    } catch (error) {
      setLoading(false)
      console.error('Error:', error)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const promptText = event.target.promptText.value
    const locationNumber = event.target.locationNumber.value
    postPrompt(promptText, locationNumber)
  }

  if (isLoading) {
    return (
      <div>
        {' '}
        <center>
          <p class="hc-fs-xl">
            <Spinner size="inline" />
            &nbsp;&nbsp;&nbsp;&nbsp;Loading
          </p>
        </center>
      </div>
    )
  }

  if (!jobcode.includes(session.userInfo.jobtitlecode)) {
    return (
      <div>
        <Grid.Container justify="center">
          <Grid.Item align="center">
            <Heading>Not Authorized</Heading>
            <br />
            <br />
            <Button onClick={logout}>Login</Button>
          </Grid.Item>
        </Grid.Container>
      </div>
    )
  }

  if (success) {
    return (
      <div>
        <Grid.Container justify="center">
          <Grid.Item align="center">
            <Heading>Your message has been updated</Heading>
            <br />
            <Button onClick={() => setSuccess(false)}>Return to Home</Button>
            <br />
            <br />
            <Button onClick={logout}>Logout</Button>
          </Grid.Item>
        </Grid.Container>
      </div>
    )
  }

  if (error) {
    return (
      <div>
        <Grid.Container justify="center">
          <Grid.Item align="center">
            <Heading>Error!</Heading>
            <br />
            <Button onClick={() => setError(false)}>Try Again</Button>
            <br />
            <br />
            <Button onClick={logout}>Logout</Button>
          </Grid.Item>
        </Grid.Container>
      </div>
    )
  }

  return (
    <div>
      <Heading>Submit Message</Heading>
      <br />
      <br />
      <br />
      <Form onSubmit={handleSubmit}>
        <Grid.Container spacing="none">
          <Grid.Item xs={4}>
            <Form.Field
              type="number"
              label="Location Number"
              defaultValue={session.userInfo.targetcorplocationnumber}
              id="locationNumber"
              hintText='Enter a location number without the "T".'
              required
            />
            <br />
          </Grid.Item>
          <Grid.Item xs={12}>
            {' '}
            ** Please verify that this is the correct number for the Crisis
            Hotline message update. **
          </Grid.Item>
          <Grid.Item xs={12}>
            <br />
            <Form.Field
              type="textarea"
              label="Message"
              id="promptText"
              hintText="Enter a message to be played to the callers."
              required
            />
            <br />
          </Grid.Item>
        </Grid.Container>
        <Grid.Container justify="space-between">
          <Grid.Item>
            <Button onClick={logout}>Logout</Button>
          </Grid.Item>
          <Grid.Item>
            <Button type="submit" id="submit">
              Submit
            </Button>
          </Grid.Item>
        </Grid.Container>
      </Form>
    </div>
  )
}
export default Message
